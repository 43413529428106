import LazyLoad from 'vanilla-lazyload';
import Console from './Console.js';


export default class Lazyload {

	/*
	** update
	*/

	static update() {

		if (this.lazyLazy !== undefined)
		{
			this.lazyLazy.update();
		}

	}

	/*
	** load
	*/

	static load() {

		Console.log('Lazyload::load');

		if (this.lazyLazy !== undefined)
		{
			Lazyload.unload();
		}

		this.lazyLazy = new LazyLoad(
		{
			elements_selector : '.lazyload'
		});

	}

	/*
	** unload
	*/

	static unload() {

		Console.log('Lazyload::unload');

		if (this.lazyLazy !== undefined)
		{
			this.lazyLazy.destroy();

			delete this.lazyLazy;
		}

	}

}
