import PageEvent from './PageEvent.js';
import Util      from './Util.js';


export default class Top extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.isMobile = Util.isMobile();

	}

	/*
	** scrollToTop
	*/

	scrollToTop () {

		Util.scrollToElement('body,html', 1000);

	}

	/*
	** resize
	*/

	resize (event) {

		this.update();

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.update();

	}

	/*
	** update
	*/

	update() {

		if (Util.getScrollTop() > 0)
		{
			this.DOM.container.classList.remove('top--hidden');
		}
		 else
		{
			this.DOM.container.classList.add('top--hidden');
		}

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// container
		this.DOM.container = document.querySelector('.top');

		// button
		this.DOM.button = this.DOM.container.querySelector('.top__button');

		this.DOM.button.addEventListener('click', event =>
		{
			this.scrollToTop();

			event.preventDefault();

		}, false);

		// update
		this.update();

	}

}
