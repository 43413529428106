import PubSub from 'pubsub-js';
import Util from '../common/Util.js';
import Page from '../common/Page.js';
import MyMasonry from '../module/MyMasonry.js';
import Observer from '../module/Observer.js';
import AOS from 'aos';
import Text from '../class/Text.js';

export default class Home extends Page {

	constructor() {

		super();

		this.name = 'home';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// panels
		this.panels();

		// projects
		this.projects();

		// observe
		this.observe_1();
		this.observe_2();

		// contact
		this.contact();

		// aos
		AOS.init();

		// text
		this.text = new Text( document.querySelector('.panel--1 .title') );

		// class
		this.DOM.body.classList.add('body--home');

	}

	/*
	** panels
	*/

	panels() {

		const p1 = document.querySelector('.panel--1 .panel__overlay');

		p1.addEventListener('click', event =>
		{
			Util.scrollToElement('.panel--2', 1000);

			event.preventDefault();

		}, false);

		const p2 = document.querySelector('.panel--2 .panel__overlay');

		p2.addEventListener('click', event =>
		{
			Util.scrollToElement('.panel--3', 1000);

			event.preventDefault();

		}, false);


		const title = document.querySelector('.panel--3 .title');

		title.addEventListener('mouseover', event =>
		{
			this.DOM.body.classList.add('body--title-over');

		}, false);

		title.addEventListener('mouseleave', event =>
		{
			this.DOM.body.classList.remove('body--title-over');

		}, false);

	}

	/*
	** observe
	*/

	observe_1() {

		this.observer_1 = new Observer('.panel', 0.99, '_1');

		this.pubSubObserverIntersect_1 = PubSub.subscribe('observer_1.intersect', (msg, data) =>
		{
			data['el'].classList.add('on-top');
		});

		this.pubSubObserverUnintersect_1 = PubSub.subscribe('observer_1.unintersect', (msg, data) =>
		{
			data['el'].classList.remove('on-top');
		});

		this.observer_1.load();

	}

	/*
	** observe
	*/

	observe_2() {

		this.observer_2 = new Observer('.projects', 0.6, '_2');

		this.pubSubObserverIntersect_2 = PubSub.subscribe('observer_2.intersect', (msg, data) =>
		{
			this.DOM.body.classList.add('body--hide-menu');
		});

		this.pubSubObserverUnintersect_2 = PubSub.subscribe('observer_2.unintersect', (msg, data) =>
		{
			this.DOM.body.classList.remove('body--hide-menu');
		});

		this.observer_2.load();

	}

	/*
	** projects
	*/

	projects() {

		this.masonry = new MyMasonry('.projects');
		this.masonry.load();

	}

	/*
	** contact
	*/

	contact() {

		const contact = document.querySelector('.contact');
		const contactBackground = document.querySelector('.contact__background');
		const contactOverlay = document.querySelector('.contact__overlay');
		const contactBtn = document.querySelector('.socials__item--contact');

		contactBtn.addEventListener('click', event =>
		{
			if ( this.DOM.body.classList.contains('body--contact') )
			{
				this.DOM.body.classList.remove('body--contact');
			}
			 else
			{
				this.DOM.body.classList.add('body--contact');
			}

			event.preventDefault();

		}, false);

		contactBackground.addEventListener('click', event =>
		{
			this.DOM.body.classList.remove('body--contact');

			event.preventDefault();

		}, false);

		contactOverlay.addEventListener('click', event =>
		{
			this.DOM.body.classList.remove('body--contact');

			event.preventDefault();

		}, false);

	}

	/*
	** enter
	*/

	enter() {

		// super
		super.enter();

		// text
		this.text.enter();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// masonry
		if (this.masonry !== undefined)
		{
			this.masonry.unload();
			delete this.masonry;
		}

		// observer_1
		if (this.observer_1 !== undefined)
		{
			PubSub.unsubscribe(this.pubSubObserverIntersect_1);
			delete this.pubSubObserverIntersect_1;

			PubSub.unsubscribe(this.pubSubObserverUnintersect_1);
			delete this.pubSubObserverUnintersect_1;

			this.observer_1.unload();
			this.observer_1 = undefined;
		}

		// observer_2
		if (this.observer_2 !== undefined)
		{
			PubSub.unsubscribe(this.pubSubObserverIntersect_2);
			delete this.pubSubObserverIntersect_2;

			PubSub.unsubscribe(this.pubSubObserverUnintersect_2);
			delete this.pubSubObserverUnintersect_2;

			this.observer_2.unload();
			this.observer_2 = undefined;
		}

		// class
		this.DOM.body.classList.remove('body--home');
		this.DOM.body.classList.remove('body--hide-menu');

	}
}