import charming from 'charming';

export default class Text
{
	constructor(el) {

		this.DOM = {el: el};

		// this.chars = ['$','%','#','@','&','(',')','=','*','/'];
		this.chars = ['_'];

		this.charsTotal = this.chars.length;

		charming(this.DOM.el);

		this.DOM.letters = this.DOM.el.querySelectorAll('span');

		this.DOM.letters.forEach(letter => letter.dataset.initial = letter.innerHTML);

		this.lettersTotal = this.DOM.letters.length;

	}

	enter() {

		this.timeouts = [];

		this.complete = false;

		let count = 0;

		this.DOM.letters.forEach( (letter, pos) =>
		{
			letter.style.opacity = 0;

			const timeout = setTimeout( () =>
			{
				letter.innerHTML = this.chars[this.getRandomInt(0, this.charsTotal-1)];

				letter.style.opacity = 1;

				setTimeout(() =>
				{
					letter.innerHTML = letter.dataset.initial;

					++ count;

					if ( count === this.lettersTotal )
					{
						this.complete = true;
					}

				}, 100);

			}, pos*80);

			this.timeouts.push(timeout);
		});

	}

	unload() {

		if ( this.complete ) return;

		for ( let i = 0, len = this.timeouts.length; i <= len - 1; ++i )
		{
			clearTimeout(this.timeouts[i]);
		}

	}

	getRandomInt (min, max) {

		return Math.floor( Math.random() * (max - min + 1) ) + min;

	}
}