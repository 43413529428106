import { createRouter } from 'routerjs';

import PubSub     from 'pubsub-js';
import Path       from './common/Path.js';
import Console    from './common/Console.js';
import Util       from './common/Util.js';
import Navigation from './Navigation.js';
import Transition from './Transition.js';


export default class App {

	constructor (baseUrl = '', debug = false) {

		Path.setBaseUrl(baseUrl);

		Console.setDebug(debug);

		this.isRouter = true;

	}

	/*
	** start
	*/

	start() {

		// viewportUnit
		this.setViewportUnit();

		// navigation
		this.navigation = new Navigation();
		this.navigation.start();

		// transition
		this.transition = new Transition();

		PubSub.subscribe('transition.load', (msg, data) =>
		{
			// navigation
		});

		this.transition.start();

		// router
		this.router(this.isRouter);

	}

	/*
	** setViewportUnit
	*/

	setViewportUnit() {

		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		window.addEventListener('resize', () =>
		{
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});

	}

	/*
	** router
	*/

	router (isRouter=false) {

		if (isRouter)
		{
			let isPopState = false;

			// scrollrestauration
			if ('scrollRestoration' in history)
			{
				history.scrollRestoration = 'manual';
			}

			// events
			window.addEventListener('popstate', (event) => {
				isPopState = true;
				return false;
			});

			// router
			const router = createRouter();

			PubSub.subscribe('router.navigate', (msg, data) =>
			{
				router.navigate(data.url);
			});

			// home
			router.get('/', (request, context) => {
				this.transition.load('home', request, isPopState);
				isPopState = false;
				request.stop();
			});

			// run
			router.run();

		}
		 else
		{
			this.transition.buildFirst();
		}

	}

}