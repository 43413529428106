import PubSub    from 'pubsub-js';
import Console   from './common/Console.js';
import PageEvent from './common/PageEvent.js';
import Util      from './common/Util.js';
import Loading   from './common/Loading.js';

export default class Navigation extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.DOM.header = document.querySelector('.header');

		this.DOM.footer = document.querySelector('.footer');

		this.lastScroll = 0;

	}

	/*
	** setActive
	*/

	static setActive (controller) {

		Console.log('Navigation::setActive('+ controller +')');

	}



	/*
	** init
	*/

	init() {

		//

	}

	/*
	** resize
	*/

	resize (event) {

		//

	}

	/*
	** scroll
	*/

	scroll (event) {

		super.scroll(event);

		// scrolled
		this.isScrolled();

		// scrollDown
		this.isScrollDown();

	}

	isScrolled() {

		if ( Util.getScrollTop() > 100 )
		{
			this.DOM.body.classList.add('body--scrolled');
		}
		 else
		{
			this.DOM.body.classList.remove('body--scrolled');
		}

	}

	isScrollDown() {

		const currentScroll = window.pageYOffset;

		if (currentScroll > this.lastScroll)
		{
			if ( !this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.add('scroll-down');
			}
		}
		 else if (currentScroll < this.lastScroll)
		{
			if ( this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.remove('scroll-down');
			}
		}

		this.lastScroll = currentScroll;

	}

	/*
	** start
	*/

	start() {

		Console.log('Navigation::start');

		// load
		this.load();

		// init
		this.init();

		// resize
		this.resize();

	}

}