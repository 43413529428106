
export default class Navigate {

	static backEnabled() {

		this.isBack = true;

	}

	static isBackEnabled() {

		if (this.isBack !== undefined)
		{
			return true;
		}
		 else
		{
			return false;
		}

	}
}