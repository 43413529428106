import PubSub       from 'pubsub-js';
import imagesLoaded from 'imagesLoaded';
import Console      from './Console.js';
import PageEvent    from './PageEvent.js';
import Util         from './Util.js';
import Lazyload     from './Lazyload.js';


export default class Page extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.name = 'page';

		this.boundOnLoad = instance => this.onLoad(instance);

	}

	/*
	** init
	*/

	init() {

		Console.log('Page::'+ this.name +'::init');

	}

	/*
	** resize
	*/

	resize (event) {

		// Console.log('Page::'+ this.name +'::resize');

		super.resize(event);

	}

	/*
	** scroll
	*/

	scroll (event) {

		// Console.log('Page::'+ this.name +'::scroll');

	}

	/*
	** load
	*/

	load() {

		Console.log('Page::'+ this.name +'::load');

		// class
		this.DOM.body.classList.add('body--'+ this.name);

		// container
		this.DOM.container = document.querySelector('.section--'+ this.name);

		// vignette
		Util.vignette('.vignette');

		// init
		this.init();

		// super
		super.load();

		// imagesLoaded
		this.imageLoaded = new imagesLoaded('.section');

		this.imageLoaded.on('done', this.boundOnLoad);

		this.imageLoaded.on('fail', () =>
		{
			Console.log('Page::'+ this.name +'::imageLoaded::fail');
		});

	}

	onLoad (instance) {

		Console.log('Page::'+ this.name +'::onLoad');

		// lazyload
		Lazyload.load();

		// ready
		PubSub.publish('page.ready');

	}

	/*
	** enter
	*/

	enter() {

		Console.log('Page::'+ this.name +'::enter');

		// class
		this.DOM.body.classList.add('body--'+ this.name +'-enter');

	}

	/*
	** leave
	*/

	leave() {

		Console.log('Page::'+ this.name +'::leave');

		// class
		this.DOM.body.classList.remove('body--'+ this.name +'-enter');

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Page::'+ this.name +'::unload');

		// super
		super.unload();

		// class
		this.DOM.body.classList.remove('body--'+ this.name);

		// imagesLoaded
		if (this.imageLoaded !== undefined)
		{
			this.imageLoaded.off('done', this.boundOnLoad);
		}

		// lazyload
		Lazyload.unload();

	}

}

