import PubSub from 'pubsub-js';
import PageEvent from '../common/PageEvent.js';

export default class Observer extends PageEvent {

	constructor (selector, ratio=0.5, id='') {

		super();

		this.id = id;

		this.selector = selector;

		this.ratio = ratio; // % from bottom

		this.observer = null;

	}

	/*
	** observe
	*/

	observe () {

		const _this = this;

		const count = this.elements.length;

		if (count > 0)
		{
			this.y = Math.round(window.innerHeight * this.ratio);

			if (this.observer !== null)
			{
				this.unobserve();
			}

			this.observer = new IntersectionObserver((entries, observer) =>
			{
				for (let i = 0; i < entries.length; ++i)
				{
					const entry = entries[i];

					if (entry.intersectionRatio > 0)
					{
						PubSub.publish('observer'+ this.id +'.intersect', {'el': entry.target});
					}
					 else
					{
						PubSub.publish('observer'+ this.id +'.unintersect', {'el': entry.target});
					}
				}

			}, {
				rootMargin: `-${window.innerHeight - this.y - 1}px 0px -${this.y}px 0px`
			});

			for (let i = 0; i < count; ++i) 
			{
				const element = this.elements[i];

				_this.observer.observe(element);
			}
		}

	}

	unobserve () {

		const _this = this;
		const count = this.elements.length;

		for (let i = 0; i < count; ++i)
		{
			const element = this.elements[i];

			_this.observer.unobserve(element);
		}

	}

	/*
	** resize
	*/

	resize (event) {

		this.observe();

	}

	/*
	** load
	*/

	load() {

		// elements
		this.elements = document.querySelectorAll(this.selector);

		// observe
		this.observe();

		// super
		super.load();

	}

	/*
	** unload
	*/

	unload() {

		// unobserve
		this.unobserve();

		// observer
		this.observer = null;

		// super
		super.unload();

	}

}