
export default class Console {

	constructor (debug = false) {

		this.debug = Console.setDebug(debug);

	}

	/*
	** setDebug
	*/

	static setDebug (value) {

		this.debug = value;

	}

	/*
	** log
	*/

	static log (msg) {

		if (this.debug)
		{
			console.log('App: ' + msg);
		}

	}

}
