import NProgress from 'nprogress';
import Console   from './Console.js';


export default class Loading {

	/*
	** show
	*/

	static show() {

		Console.log('Loading::show');

		NProgress.start();

	}

	/*
	** hide
	*/

	static hide() {

		Console.log('Loading::hide');

		NProgress.done();

	}

}
